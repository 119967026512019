<template>
    <div>
        <v-card :loading="loading">
            <v-form :disabled="loading" @submit.prevent="getList()" ref="form">
                <v-card-title>Фильтр</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-select
                                label="Статус"
                                :items="status"
                                clearable
                                v-model="search.status"
                            />
                        </v-col>
                        <v-col>
                            <v-select
                                label="Категория"
                                clearable
                                :items="cats"
                                v-model="search.cat"
                                @change="search.tags = []"
                            />
                        </v-col>
                        <v-col>
                            <v-select
                                label="Тег"
                                :items="selectTags"
                                clearable
                                multiple
                                v-model="search.tags"
                                :disabled="!search.cat"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        text
                        color="error"
                        :disabled="loading"
                        @click="reset"
                    >
                        Сброс
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        :disabled="loading"
                        type="submit"
                    >
                        Поиск
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
        <v-card class="mt-4" :loading="loading">
            <v-toolbar flat>
                <v-toolbar-title>Список</v-toolbar-title>
                <v-spacer />
                <v-btn icon :to="{ name: 'NewsOne', params: { id: 0 } }">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-toolbar>
            <v-data-table
                disable-sort
                :items="list"
                :headers="headers"
                disable-pagination
                hide-default-footer
            >
                <template #[`item.actions`]="{ item: { id } }">
                    <v-btn icon :to="{ name: 'NewsOne', params: { id } }">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination
                :value="pg"
                :length="total"
                @input="getList"
                v-if="total > 1"
            />
        </v-card>
    </div>
</template> 

<script>
export default {
    name: "NewsList",
    data: () => ({
        loading: false,
        search: {
            status: null,
            cat: null,
            tags: [],
        },
        pg: 1,
        total: 0,
        status: [],
        cats: [],
        tags: {},
        list: [],
        first: true,
    }),
    computed: {
        headers: () => [
            {
                text: "#",
                value: "id",
            },
            {
                text: "Имя",
                value: "name",
            },
            {
                text: "Категория",
                value: "category",
            },
            {
                text: "Статус",
                value: "status",
            },
            {
                text: "Дата публикации",
                value: "publish",
            },
            {
                text: "Теги",
                value: "tags",
            },
            {
                value: "actions",
            },
        ],
        selectTags() {
            return this.tags[this.search.cat] ?? [];
        },
    },
    mounted() {
        this.getList();
    },
    methods: {
        reset() {
            this.$refs.form.reset();
            this.getList();
        },
        async getList(pg = 1) {
            this.loading = true;
            const q = new URLSearchParams();
            q.append("first", this.first ? 1 : 0);
            q.append("pg", pg);
            if (this.search.status != null) {
                q.append("flt[status]", this.search.status);
            }
            if (this.search.cat) {
                q.append("flt[cat]", this.search.cat);
            }
            if (this.search.tags.length) {
                this.search.tags.forEach((t) => {
                    q.append("flt[tag][]", t);
                });
            }
            const { list, status, cats, tags, total } = await this.$admin(
                `/news?${q.toString()}`
            );
            if (this.first) {
                this.status = status;
                this.cats = cats;
                this.tags = tags;
                this.first = false;
            }
            this.pg = pg;
            this.total = total;
            this.list = list;
            this.loading = false;
        },
    },
};
</script>